import { FunctionComponent } from 'react'
import { GetStaticPaths, GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'

import { SupportedLocale } from 'data-access/domain/constants'

import MisterLayout from 'src/components/layout/MisterLayout'
import { getGenericPageProps } from 'src/domain/genericPageProps.domain'
import { getPagePaths } from 'src/domain/pagePaths.domain'
import { seoData } from 'src/utils/seo.util'
import MisterSectionLoop from '../components/shared/sections/MisterSectionLoop'
import { defaultGlobals, GlobalsContext } from '../contexts/Globals.context'

const GenericPage: FunctionComponent<InferGetStaticPropsType<typeof getStaticProps>> = ({ globals, layoutProps, sections, seo }) => {
  return (
    <GlobalsContext.Provider value={globals || defaultGlobals}>
      <NextSeo {...seoData(seo)} />
      <MisterLayout {...layoutProps}>
        <MisterSectionLoop sections={sections} as='article' />
      </MisterLayout>
    </GlobalsContext.Provider>
  )
}

export default GenericPage

export const getStaticProps = async ({ locale, params: { slug } = {}, draftMode = false }: GetStaticPropsContext) => {
  const props = await getGenericPageProps({ locale: locale as SupportedLocale, slug: String(slug), draftMode })

  return !props ? { notFound: true } : { props }
}

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths = await getPagePaths({
    types: ['page', 'pageWishlist', 'pageSearch', 'pageWithRequirements'],
    locales: locales as SupportedLocale[],
    paramName: 'slug',
  })

  return {
    paths,
    fallback: 'blocking',
  }
}
